.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*, body, input, textarea {
  
}

* {
	font-family: monospace !important;
}

.topRow {
  min-height: 96px !important;
}

textarea {
  cursor: auto;
}

input, textarea {
  border-radius: 0px !important;
}

.container {
 
}

.qrcode {
  min-height: 224px;
}

.padded-container {
  margin-left: 0px;
  margin-right: 0px;
}